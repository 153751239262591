<template>
  <loader v-bind="{ loading }" text="Loading Customers">
    <columns>
      <column>
        <page-heading
          heading="Customers"
          sub-heading="A list of the customers your company works with."
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for customers...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <router-link 
                  v-if="!$root.isPassiveUser()"
                  class="button is-rounded is-flex" 
                  :to="{
                    name: 'create-customer'
                  }">
                    <icon icon="plus"/>
                    <span>Customer</span>
                  </router-link>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>

    <columns>
      <column>
        <action-button :working="downloading" @click="downloadExcel" left-icon="download" class="is-small is-rounded">
          Excel
        </action-button>
      </column>
    </columns>

    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="customer in customers.data" :key="customer.id">
            <columns>
              <column>
                <router-link :to="{
                  name: 'customer-manager',
                  params: {
                    customer: customer.uuid
                  }
                }">
                  <span class="mr-2">{{ customer.name }}</span>
                </router-link>
                <p>
                  <small class="has-text-grey">Updated {{ customer.updated_at | asCalendar }}</small>
                </p>
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  icon="hashtag" 
                  label="Equipment" 
                  :info="customer.equipment_count"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  icon="map-location-dot" 
                  label="Locations" 
                  :info="customer.locations_count"
                />
              </column>
            </columns>
          </div>
        </div>

        <pager v-if="customers.data.length" :pageable="customers" context="Customer" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="customers.data.length"
          with-link
          heading="There are no customers available for display."
          :to="{
            name: 'create-customer'
          }"
          to-text="New Customer"
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { common as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    downloading: false,
    filters: {
      search: ''
    },
    customers: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadCustomers()
  },

  methods: {
    loadCustomers() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.customers = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'customers',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'customers',
      });
    },
    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/customers/download-xls`, ({ data }) => {
        download(data, `customers.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, this.filters)
    }
  },

  watch: {
    '$route': 'loadCustomers'
  }

}
</script>