var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Customers"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Customers","sub-heading":"A list of the customers your company works with."}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search for customers..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),(!_vm.$root.isPassiveUser())?_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                  name: 'create-customer'
                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Customer")])],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('action-button',{staticClass:"is-small is-rounded",attrs:{"working":_vm.downloading,"left-icon":"download"},on:{"click":_vm.downloadExcel}},[_vm._v(" Excel ")])],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.customers.data),function(customer){return _c('div',{key:customer.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{attrs:{"to":{
                name: 'customer-manager',
                params: {
                  customer: customer.uuid
                }
              }}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(customer.name))])]),_c('p',[_c('small',{staticClass:"has-text-grey"},[_vm._v("Updated "+_vm._s(_vm._f("asCalendar")(customer.updated_at)))])])],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"hashtag","label":"Equipment","info":customer.equipment_count}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"map-location-dot","label":"Locations","info":customer.locations_count}})],1)],1)],1)}),0),(_vm.customers.data.length)?_c('pager',{attrs:{"pageable":_vm.customers,"context":"Customer","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.customers.data.length,"with-link":"","heading":"There are no customers available for display.","to":{
          name: 'create-customer'
        },"to-text":"New Customer"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }